import React, { Fragment, useEffect, useState } from 'react'
import { apiCall } from '@services/api'
import { SEOData } from '@serverTypes'

export const SEOModule = () => {
  const [seoData, setSeoData] = useState<SEOData | undefined>()

  useEffect(() => {
    apiCall<SEOData>('admin', 'getSEOData').then(setSeoData)
  }, [])

  if (!seoData) {
    return <Fragment/>
  }

  return (
    <div>
      <table>
        <tbody>
        <tr><td>Ext. links:</td><td><pre>{ JSON.stringify(seoData.extLinks) }</pre></td></tr>
        </tbody>
      </table>
    </div>
  )
}
