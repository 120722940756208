import React, { useCallback, useEffect, useState } from 'react'
import { Checkbox } from '@components/UI/Checkbox'
import { vendorsToList } from '@utils/library'
import { Select } from '@components/UI/Select'
import { Button } from '@components/UI/Button'
import { toast } from '@components/UI/Toaster'
import { Input } from '@components/UI/Input'
import { Row } from '@components/UI/Row'
import { useRandom } from '@utils/hooks'
import { apiCall } from '@services/api'
import { Vendor } from '@serverTypes'

export const VendorEditorModule = () => {
  const R = useRandom()
  const [vendors, setVendors] = useState<Array<Vendor>>([])
  const [badId, setBadId] = useState(0)
  const [goodId, setGoodId] = useState(0)
  const [selectedId, setSelectedId] = useState(0)

  const vendorIndex = vendors.map(v => v.id).indexOf(selectedId)

  const saveVendor = useCallback(async () => {
    if (await apiCall<Vendor>('admin', 'updateVendor', {vendor: vendors[vendorIndex]})) {
      toast({content: 'Vendor saved'})
    }
  }, [vendorIndex, vendors])

  const linkVendors = useCallback(async () => {
    if (window.confirm('Are you sure you want to link vendors?')) {
      if (await apiCall<Vendor>('admin', 'linkVendors', {badId, goodId})) {
        toast({content: 'Vendors merged'})
      }
    }
  }, [badId, goodId])

  useEffect(() => {
    apiCall<Array<Vendor>>('library', 'getVendors').then(x => setVendors(x || []))
  }, [])

  return (
    <div>
      <fieldset className="row">
        <legend>Link</legend>
        <Row bottom>
          <Select<number> label="Bad vendor" items={vendorsToList(vendors)} value={badId} onChange={setBadId} />
          <Select<number> label="Good vendor" items={vendorsToList(vendors)} value={goodId} onChange={setGoodId} />
          {
            badId && goodId ?
              <Button onClick={linkVendors}>Link</Button>
              : null
          }
        </Row>
      </fieldset>
      <br/>
      <fieldset className="column">
        <legend>Edit</legend>
        <Select<number> label="Vendor" items={vendorsToList(vendors, true)} value={selectedId} onChange={setSelectedId} />
        {
          vendorIndex !== -1 ?
            <>
              <Input label="Name" value={vendors[vendorIndex].name} onChange={x => vendors[vendorIndex].name = R(x) } />
              <Checkbox label="Verified" value={Boolean(vendors[vendorIndex].verified)} onChange={x => vendors[vendorIndex].verified = R(x) } />
              <br/>
              <Button onClick={saveVendor}>Save</Button>
            </>
            : null
        }
      </fieldset>
    </div>
  )
}
