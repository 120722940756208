import http from 'http'

export type AppConfig = {
  env: 'local' | 'stage' | 'prod'
  server: {
    port: number
    ssrPort: number
  },
  client: {
    origin: string
  },
  session: {
    secret: string
  }
  debug: {
    noImages: boolean
  },
  sentry: {
    dsn: string | false
  }
  vatLayer: {
    token: string
  }
  sendinblue: {
    apiKey: string
  }
}

export type HttpRequest = http.IncomingMessage & {
  user: Record<string, any>
  data: string
  headers: Record<string, string>
  json: Record<string, any> | null
  query: Record<string, any>
  connection: Record<string, any> | null
  path: string
}

export type HttpResponse = http.ServerResponse

export type RouteConfig = {
  auth?: boolean
}

export type APIResponse<T = Record<string, any>> = {
  isError: boolean
  errMsg?: {
    code: number
    message: string
  }
  data: null | T
}

export type SessionJWT = {
  id: number
}

// eslint-disable-next-line
enum AuthenticationType {
  self = 0,
}

export type KV = {
  k: number
  v: string
}

export type AutoInsert<T> = Omit<Omit<Omit<T, 'id'>, 'created_at'>, 'createdAt'>
export type AutoUpdate<T> = Omit<Omit<T, 'updated_at'>, 'updatedAt'> & {updated_at: string | null, updatedAt?: Date}

export type ServerHealth = {
  imagesDirSize: number
  imagesCount: number
  backupsDirCount: number
  dbSize: number
  dbJournalSize: number
  syncLogContent: string
}

export type SEOData = {
  extLinks: Record<string, boolean>
}

export type VATObject = {
  valid: boolean
  company: string
  address: string
  countryCode: string
}

export type Coords = {
  lat: number
  lon: number
}

export type SortConfig = Array<{
  orderBy: string
  desc?: boolean
}>

// =======

export enum AdDirection {
  Sell = 0,
  Buy = 1,
}

export enum AdState {
  Draft = 0,
  Active = 1,
  Sold = 2,
  Hidden = 3,
}

export enum AdItemType {      // what is this ad about
  Generic = 0,
  FullLink = 1,               // Ad is fully linked, itemId = goodAd.id
  Ultralight = 2,             // subject to renaming
}

export enum AircraftKind {
  Unknown = 0,
  UL_Airplane = 1,            // in the MVP we only deal with ultralight airplanes
  UL_Autogyro = 2,
  UL_Helicopter = 3,
}

export enum AircraftState {
  New = 0,
  Used = 1,
  NeedsRepair = 2,
}

export enum AircraftStorage {
  Unknown = 0,
  Hangar = 1,
  Outside = 2,
  Trailer = 3,
  Other = 4,
}

export enum AircraftAccidents {
  None = 0,
  ServiceRepaired = 1,
  SelfRepaired = 2,
  NotRepaired = 3,
  Unknown = 4,
}

export enum AirfieldType {
  Unknown = 0,
  Closed = 1,
  BalloonPort = 2,
  Heliport = 3,
  SeaplaneBase = 4,
  AirportSmall = 6,
  AirportMedium = 7,
  AirportLarge = 8,
}

export type Vendor = {
  id: number
  name: string
  verified?: boolean
  createdAt: Date
  updatedAt?: Date
}

export type AircraftType = {
  id: number
  code: string
  name: string
  vendorId: number
  verified?: boolean
  createdAt: Date
  updatedAt?: Date
}

/* export type Engine = {
  id: number
  name: string
  vendorId: number
  power: number
} */

export type Equipment = {   // up to 32 (64?) flags
  vario: boolean
  turn: boolean
  horizon: boolean
  clock: boolean
  heading: boolean
  navigation: boolean

  ifr: boolean
  transponder: boolean
  flarm: boolean
  tcas: boolean
  elt: boolean
  stallWarning: boolean

  radio: boolean
  autopilot: boolean
  elTrim: boolean
  elFlaps: boolean
  heating: boolean
  parachute: boolean
}

export type AdFlags = {
  withVat: boolean
  withInvoice: boolean
}

export type FullTrackedEntity = {
  id: number
  createdAt: Date
  updatedAt?: Date
}

export type File = FullTrackedEntity & {
  uuid: string
  comment?: string
}

export type PreFile = {
  pathTemplate: string
  uuid: string
}

export type User = FullTrackedEntity & {
  name?: string
  email?: string
  phone?: string
  vat?: string
  credential?: string
}

export type Aircraft = {
  id: number
  kind: AircraftKind
  typeId: number                    // aircraftType.id
  state: AircraftState
  storage: AircraftStorage
  accidents: AircraftAccidents
  equipment: Equipment              // binary flags with Equipment
  airfield?: string                 // airfield code
  tailNumber?: string
  emptyWeight?: number
  mtow?: number
  engineCount: number
  engineHours?: number
  engine?: string
  hours: number
  seats: number
  landings?: number
  builtAt?: Date
  nextCheck?: Date
  nextParaCheck?: Date
}

export type Ad = FullTrackedEntity & {
  userId?: number
  providerId: number
  externalId?: string
  direction: AdDirection
  state: AdState
  itemId: number                    // e.g. aircraft.id
  itemType: AdItemType
  description?: string
  flags: AdFlags                    // binary flags with AdFlags
  price: number                     // in 1/100
  currency: string
  photoIds: string                  // pipe-separated file IDs
}

export type RichAircraftAd = FullTrackedEntity & {
  user: User
  providerId: number
  externalId: string
  direction: AdDirection
  state: AdState
  aircraft: Omit<Aircraft, 'typeId'> & { type: Omit<AircraftType, 'vendorId'>, vendor: Vendor }
  description: string
  flags: AdFlags
  price: number
  currency: string
  files: Array<File>
}

export type RichAircraftType = Omit<AircraftType, 'vendorId'> & { vendor: Vendor }

export type Airfield = FullTrackedEntity & {
  code: string
  type: AirfieldType
  name: string
  elevation?: number
  country: string
  city?: string
  coords: string
}

export type EnhancedAirfield = {
  airfield: Airfield | undefined
  suggestions: Array<string>
  article?: string
}
