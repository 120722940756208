import { apiCall, APIPagination } from './api'
import { RichAircraftAd } from '@serverTypes'

type AdsFilter = {
  token?: string
}

export interface GetItemFeedIn {
  filter?: AdsFilter
  pagination?: APIPagination
}

export interface GetItemFeedOut {
  items: Array<RichAircraftAd>
  stats: {
    total: number
  }
}

const _itemCache: Record<number, RichAircraftAd> = {}

export const getItemFeed = async ({filter, pagination}: GetItemFeedIn): Promise<GetItemFeedOut> => {
  const result = (await apiCall<GetItemFeedOut>('item', 'feed', {filter, pagination})) ?? {
    items: [],
    stats: {
      total: 0,
    },
  }

  for (const item of result.items) {
    _itemCache[item.id] = item
  }

  return result
}

export const getItemById = async (id: number): Promise<RichAircraftAd | undefined> => {
  let item: RichAircraftAd | undefined = _itemCache[id]

  if (!item) {
    item = await apiCall<RichAircraftAd>('item', 'getById', {id})
  }

  return Promise.resolve(item)
}
