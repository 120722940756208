import { Vendor, RichAircraftType, User } from '@serverTypes'
import { ListItems } from '@types'

export const usersToList = (users: Array<User>): ListItems<number> => {
  const results = users.map(u => ({value: u.id, label: `${u.email || u.name}, ${u.phone || u.name}`}))

  results.unshift({value: 0, label: '—'})

  return results
}

export const vendorsToList = (vendors: Array<Vendor>, withId?: boolean): ListItems<number> => {
  const results = vendors.map(v => ({value: v.id, label: withId ? `${v.name} (#${v.id})` : v.name}))

  results.unshift({value: 0, label: '—'})

  return results
}

export const richAircraftTypesToList = (richAircraftTypes: Array<RichAircraftType>, withId?: boolean): ListItems<number> => {
  const results = richAircraftTypes.map(rat => ({value: rat.id, label: `${rat.vendor.name} - ${rat.name}${withId? ` (#${rat.id})` : ''}`}))

  results.unshift({value: 0, label: '—'})

  return results
}

export const getAircraftYearsList = (): ListItems<string> => {
  const items: ListItems<string> = [{
    label: '—',
    value: '0000',
  }]

  for (let y = (new Date()).getFullYear(); y >= 1980; y--) {
    items.push({value: String(y), label: String(y)})
  }

  return items
}

export const getCurrenciesList = (): ListItems<string> => {
  return [
    { label: '—', value: '' },
    { label: 'EUR', value: 'EUR' },
    { label: 'USD', value: 'USD' },
  ]
}

export const getAdStatesList = (): ListItems<number> => {
  return [
    { label: 'Draft', value: 0 },
    { label: 'Active', value: 1 },
    { label: 'Sold', value: 2 },
    { label: 'Hidden', value: 3 },
  ]
}
