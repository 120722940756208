import React, { forwardRef, useCallback, useEffect, useState } from 'react'

export type SafeImageProps = React.ImgHTMLAttributes<HTMLImageElement> & {
  alt: string
  src: string
}

const CHANNEL_MAIN    = 1
const CHANNEL_BACKUP  = 2

export const SafeImage = forwardRef<HTMLImageElement, SafeImageProps>((props, ref) => {
  const [localSrc, setLocalSrc] = useState(props.src)
  const [channel, setChannel] = useState(CHANNEL_MAIN)

  const onError = useCallback(() => {
    if (channel === CHANNEL_MAIN) {
      // try backup
      setLocalSrc((oldSrc) => {
        if (oldSrc.includes('-thumb')) {
          return oldSrc.replace('-thumb', '')
        } else {
          return oldSrc.replace('.jpg', '-thumb.jpg')
        }
      })

      setChannel(CHANNEL_BACKUP)
    }
  },[channel])

  useEffect(() => {
    setChannel(CHANNEL_MAIN)
    setLocalSrc(props.src)
  }, [props.src])

  return (
    <img
      ref={ref}
      {...props}
      src={localSrc}
      alt={props.alt}
      onError={onError}
    />
  )
})
