export function deepCopy<T> (obj: T): T {
  if (typeof obj !== 'object' || obj === null) return obj

  let copy;
  if (Array.isArray(obj)) {
    copy = []
    for (let i = 0; i < obj.length; i++) {
      copy.push(deepCopy(obj[i]));
    }
  } else {
    copy = {}
    for (const key in obj) {
      // @ts-ignore
      copy[key] = deepCopy(obj[key])
    }
  }
  // @ts-ignore
  return copy
}
