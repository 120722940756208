import { richAircraftTypesToList, getAircraftYearsList, getAdStatesList } from '@utils/library'
import { RichAircraftType, Equipment, RichAircraftAd, AdState } from '@serverTypes'
import React, { useCallback, useEffect, useState } from 'react'
import { useCopies, useRandom } from '@utils/hooks'
import { Checkbox } from '@components/UI/Checkbox'
import { SvgIcon } from '@components/UI/SvgIcon'
import { Select } from '@components/UI/Select'
import { Button } from '@components/UI/Button'
import { toast } from '@components/UI/Toaster'
import { Input } from '@components/UI/Input'
import { getConfig } from '@services/config'
import { Row } from '@components/UI/Row'
import { apiCall } from '@services/api'
import './index.css'

type LastAd = RichAircraftAd & {
  externalLink: string
}

export const LastAdsModule = () => {
  const R = useRandom()
  const [data, setData] = useState<Array<LastAd>>([])
  const [sortMode, setSortMode] = useState<'id'|'createdAt'>('createdAt')
  const [aircraftTypes, setAircraftTypes] = useState<Array<RichAircraftType>>([])
  const eqCopies = useCopies<Record<keyof Equipment, string>>('equipment')

  const saveAd = useCallback(async (i: number) => {
    if (await apiCall<LastAd>('admin', 'updateAd', {ad: data[i]})) {
      toast({content: 'Ad saved'})
    }
  }, [data])

  const deleteImage = useCallback((i: number, fId: number) => {
    if (window.confirm('Are you sure you want to remove this image?')) {
      data[i].files = R(data[i].files.filter(f => f.id !== fId))
    }
  }, [data, R])

  useEffect(() => {
    apiCall<Array<RichAircraftType>>('library', 'getAircraftTypesRich').then(x => setAircraftTypes(x || []))
    apiCall<Array<LastAd>>('admin', 'getLastAds', {sort: [{orderBy: sortMode}]}).then(x => setData(x || []))
  }, [sortMode])

  return (
    <div>
      <div className="last-ad__sorting">
        <Select label="Ad sorting" value={sortMode} items={[{value: 'id', label: 'ID'}, {value: 'createdAt', label: 'Created at + poor img'}]} onChange={x => setSortMode(x)} />
      </div>
      <hr/>
      {
        (data || []).map((ad, i) => {
          return (
            <div key={ad.id} className={`last-ad ad-${ad.id}`}>
              <div>
                <span className="last-ad__id">#{ad.id} </span>
                <a className="last-ad__ext-link" href={ad.externalLink} rel="noreferrer" target="_blank">{ad.externalLink}</a>
                <span className="last-ad__created-at">🗓️ { String(ad.createdAt).split('T')[0] }</span>
              </div>
              <br/>
              <div className="last-ad__images">
                {
                  ad.files.map((file, j) =>
                    <div className="last-ad__image" key={file.id} title="Delete image">
                      <a target="_blank" rel="noreferrer" href={`${getConfig().urlPrefix}/data/images/${file.uuid}.jpg`}>
                        <img
                          alt=""
                          title={`File # ${file.id}`}
                          src={
                            ad.providerId === 1 ?
                              `${getConfig().urlPrefix}/data/images/${file.uuid}.jpg`
                              : `${getConfig().urlPrefix}/data/images/${file.uuid}-thumb.jpg`
                          }
                        />
                      </a>
                      <div className="last-ad__image-delete" onClick={() => deleteImage(i, file.id)}>
                        <SvgIcon code="close" />
                      </div>
                    </div>
                  )
                }
              </div>
              <div className="last-ad__info">
                <div>
                  <pre>
                    { ad.description }
                  </pre>
                </div>
                <div style={{maxWidth: '20rem'}}>
                  <Select label="Ad state" value={ad.state} items={getAdStatesList()} onChange={x => data[i].state = R(x) } />
                  {
                    ad.state === AdState.Active ?
                      <>
                        <Row>
                          <Checkbox label="Vith VAT" value={ad.flags.withVat} onChange={x => data[i].flags.withVat = R(x)} />
                          &nbsp;&nbsp;&nbsp;
                          <Checkbox label="Vith invoice" value={ad.flags.withInvoice} onChange={x => data[i].flags.withInvoice = R(x)} />
                        </Row>
                        <Select
                          label="Type"
                          value={ad.aircraft.type.id ?? 0}
                          items={richAircraftTypesToList(aircraftTypes)}
                          skin={ad.aircraft.type.id ? undefined : 'error'}
                          onChange={x => data[i].aircraft.type.id = R(x) }
                        />
                        <Input label="Engine model" value={ad.aircraft.engine} onChange={x => data[i].aircraft.engine = R(x) } />
                        <Row>
                          <Input label="Engine hours" value={ad.aircraft.engineHours} onChange={x => data[i].aircraft.engineHours = R(x) } />
                          &nbsp;
                          <Input label="Hours" value={ad.aircraft.hours} onChange={x => data[i].aircraft.hours = R(x) } />
                        </Row>
                        <Row>
                          <Input label="Tail number" value={ad.aircraft.tailNumber} onChange={x => data[i].aircraft.tailNumber = R(x) } />
                          &nbsp;
                          <Input label="Location" value={ad.aircraft.airfield} onChange={x => data[i].aircraft.airfield = R(x) } />
                        </Row>
                        <Select
                          label="Year"
                          items={getAircraftYearsList()}
                          value={ad.aircraft.builtAt ? String(ad.aircraft.builtAt).split('-')[0] : ''}
                          onChange={x => ad.aircraft.builtAt = R(`${x}-01-01T00:00:00.000Z`)}
                        />
                        <Input
                          label="Price"
                          value={ad.price}
                          onChange={x => data[i].price = R(x) }
                          skin={!data[i].price ? 'error' : undefined}
                        />
                      </>
                      : null
                  }
                  <Button onClick={() => saveAd(i)}>Save</Button>
                </div>
                <div>
                  {
                    ad.state === AdState.Active ? (Object.keys(eqCopies) as Array<keyof Equipment>).map((eq, j) =>
                      <Checkbox
                        key={j}
                        label={eqCopies[eq]}
                        value={ad.aircraft.equipment[eq]}
                        onChange={x => data[i].aircraft.equipment[eq] = R(x)}
                      />
                    ) : null
                  }
                </div>
              </div>
              <hr/>
            </div>
          )
        })
      }
    </div>
  )
}
