import { useLocationLanguagePrefix } from '@utils/hooks'
import { useLocation } from 'react-router-dom'
import i18n, { t } from 'i18next'
import React from 'react'
import './index.css'

type FooterProps = {
  vendorToken?: string
  typeToken?: string
}

const copyMap: Record<string, string> = {
  en: 'in English',
  de: 'auf Deutsch',
  fr: 'en français',
}

export const Footer = (props: FooterProps) => {
  const location = useLocation()
  const pfx = useLocationLanguagePrefix()
  const { vendorToken, typeToken } = props

  return (
    <footer>
      {
        Object.keys(copyMap).map((lang: string, i: number) => {
          if (lang === i18n.language) {
            return false
          }

          const segments = location.pathname.split('/')
          segments[1] = lang
          const result = segments.join('/')

          return <a key={i} href={`${result === '/en' ? '/' : result}`}>{ copyMap[lang] }</a>
        }).filter(Boolean)
      }
      {
        vendorToken ?
          <span>&mdash; &nbsp;<a href={`${pfx}/search/${vendorToken.toLowerCase().trim()}`}>{ t('footer.vendorSearch', {token: vendorToken}) }</a></span>
          : null
      }
      {
        typeToken ?
          <span>&mdash; &nbsp;<a href={`${pfx}/search/${typeToken.toLowerCase().trim()}`}>{ t('footer.typeSearch', {token: typeToken}) }</a></span>
          : null
      }
    </footer>
  )
}
