import { Routes, Route, Outlet, Navigate } from 'react-router-dom'
import { VFRPageLoader } from '@pages/lab/vfr/dynamicImport'
import { useLocationLanguagePrefix } from '@utils/hooks'
import { AirfieldPage } from '@pages/airfield'
import { storage } from '@services/storage'
import { LoginPage } from '@pages/login'
import { AdminPage } from '@pages/admin'
import React, { useEffect } from 'react'
import { HomePage } from '@pages/home'
import { ItemPage } from '@pages/item'
import i18n from 'i18next'

function Layout() {
  return (
    <Outlet />
  )
}

function App() {
  const languagePrefix = useLocationLanguagePrefix()

  useEffect(() => {
    void storage.init()
    void i18n.changeLanguage(languagePrefix.replace('/', '') ?? 'en')
  }, [languagePrefix])

  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<HomePage />} />
        <Route path="/lab/vfr" element={<VFRPageLoader />} />
        <Route path="/admin" element={<AdminPage />} />
        <Route path="/admin/:moduleId" element={<AdminPage />} />
        <Route path="/:language/login" element={<LoginPage />} />
        <Route path="/:language/feed/:pageId" element={<HomePage />} />
        <Route path="/:language/search/:searchToken" element={<HomePage />} />
        <Route path="/:language/search/:searchName/:itemId" element={<ItemPage />} />
        <Route path="/:language/airfield/:code" element={<AirfieldPage />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Route>
      <Route path="/:language" element={<HomePage />} />
    </Routes>
  )
}

export default App
