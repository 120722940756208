import React, { useCallback, useEffect, useRef, useState } from 'react'
import { mergeRichAdStructures } from '@utils/ad'
import { Button } from '@components/UI/Button'
import { RichAircraftAd } from '@serverTypes'
import { Input } from '@components/UI/Input'
import { Row } from '@components/UI/Row'
import { apiCall } from '@services/api'
import './index.css'

export const MergeAdsModule = () => {
  const refEditor = useRef<HTMLTextAreaElement>(null)
  const [id1, setId1] = useState('')
  const [id2, setId2] = useState('')
  const [ad1, setAd1] = useState<RichAircraftAd|undefined>(undefined)
  const [ad2, setAd2] = useState<RichAircraftAd|undefined>(undefined)
  const [adRaw, setAdRaw] = useState<string>('?')

  const fetchAd1 = useCallback(() => {
    id1 && apiCall<RichAircraftAd>('admin', 'getAdById', {id: id1}).then(x => setAd1(x))
  }, [id1])

  const fetchAd2 = useCallback(() => {
    id2 && apiCall<RichAircraftAd>('admin', 'getAdById', {id: id2}).then(x => setAd2(x))
  }, [id2])

  const mergeAds = useCallback(() => {
    if (refEditor.current) {
      let json = undefined
      const raw = refEditor.current.value

      try {
        json = JSON.parse(raw)
      } catch (e: any) {
        console.log(e)
        alert('Invalid JSON')
      }

      if (json) {
        if (window.confirm('JSON valid. Sure to merge?')) {
          void apiCall<RichAircraftAd>('admin', 'linkDuplicateAd', {badId: id1, goodId: id2, ad: json})
        }
      }
    }
  }, [refEditor, id1, id2])

  useEffect(() => {
    if (ad1 && ad2) {
      setAdRaw(JSON.stringify(mergeRichAdStructures(ad1, ad2), null, 2))
    }
  }, [ad1, ad2])

  return (
    <div className="merge-ads">
      <div>
        <Row center>
          <Input value={id1} onChange={setId1} />
          <Button onClick={fetchAd1}>Fetch</Button>
        </Row>
        <pre>{JSON.stringify(ad1, null, 2)}</pre>
      </div>
      <div>
        <Row center>
          <Input value={id2} onChange={setId2} />
          <Button onClick={fetchAd2}>Fetch</Button>
        </Row>
        <pre>{JSON.stringify(ad2, null, 2)}</pre>
      </div>
      <div>
        <Button onClick={mergeAds}>Merge</Button>
        <textarea
          ref={refEditor}
          value={adRaw}
          onChange={ev => setAdRaw(ev.target.value)}
        />
      </div>
    </div>
  )
}
