import React, { Fragment, useEffect, useState } from 'react'
import { ServerHealth } from '@serverTypes'
import { apiCall } from '@services/api'

export const ServerHealthModule = () => {
  const [serverHealth, setServerHealth] = useState<ServerHealth | undefined>()

  useEffect(() => {
    apiCall<ServerHealth>('admin', 'getServerHealth').then(setServerHealth)
  }, [])

  if (!serverHealth) {
    return <Fragment/>
  }

  return (
    <div>
      <table>
        <tbody>
        <tr><td>Image dir size:</td><td>{ (serverHealth.imagesDirSize / 1048576).toFixed(2) } MB</td></tr>
        <tr><td>Images count:</td><td>{ serverHealth.imagesCount }</td></tr>
        <tr><td>Backups size</td><td>{ serverHealth.backupsDirCount }</td></tr>
        <tr><td>DB size</td><td>{ (serverHealth.dbSize / 1048576).toFixed(2) } MB</td></tr>
        <tr><td>DB journal</td><td>{ (serverHealth.dbJournalSize / 1024).toFixed(2) } kB</td></tr>
        <tr><td>Sync log</td><td><pre>{ serverHealth.syncLogContent }</pre></td></tr>
        </tbody>
      </table>
    </div>
  )
}
