import { Color, getColorCode } from '@utils/color'
import React from 'react'

export interface SvgAnimationProps extends React.SVGAttributes<SVGElement> {
  size?: number
  color: Color
  animation?: string
}

export const SvgAnimation = (props: SvgAnimationProps): JSX.Element => {
  const { size, color, ...rest } = props

  return (
    <svg
      version="1.1"
      xmlSpace="preserve"
      viewBox="20 20 60 60"
      enableBackground="new 0 0 0 0"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      style={{width: `${size}rem`, height: `${size}rem`}}
    >
      <path
        fill={getColorCode(color)}
        d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"
      >
        <animateTransform
          dur="1s"
          type="rotate"
          from="0 50 50"
          to="360 50 50"
          attributeType="XML"
          repeatCount="indefinite"
          attributeName="transform"
          { ...rest }
        />
      </path>
    </svg>
  )
}

SvgAnimation.defaultProps = {
  size: 1.5,
  animation: 'spinner',
  color: 'white'
}
