import React, { useCallback, useRef, useState, KeyboardEvent } from 'react'
import { useOneUnderAnother } from '@utils/hooks'
import { SvgIcon } from '@components/UI/SvgIcon'
import { computeZIndex, getParentByClass } from '@utils/dom'
import { Popup } from '@components/UI/Popup'
import { Input } from '@components/UI/Input'
import { ListItem, ListItems } from '@types'
import './index.css'

type SelectProps<T> = {
  value: T
  items: ListItems<T>
  label?: string
  placeholder?: string
  onChange: (v: T) => void
  skin?: 'error'
}

export function Select<T extends string|number>(props: SelectProps<T>) {
  const { value, items, onChange, placeholder, ...rest } = props

  const [token, setToken] = useState('')
  const [toggled, setToggled] = useState(false)
  const ref = useRef<HTMLInputElement>(null)
  const refInput = useRef<HTMLInputElement>(null)
  const refList = useRef<HTMLDivElement>(null)

  const _onChange = useCallback((ev: any) => {
    const base = getParentByClass(ev.target, 'select__li')
    const dataValue = base?.dataset.value

    if (!dataValue) {
      return
    }

    // @ts-ignore
    onChange(typeof value === 'number' ? Number(dataValue) : dataValue)
    setToggled(false)
  }, [onChange, value])

  const onKeyDown = useCallback((ev: KeyboardEvent<HTMLInputElement>) => {
    if (ev.code === 'Escape') {
      setToggled(false)
    }
  }, [])

  const filterFunction = (item: ListItem<any>) => {
    if (token.length < 2) return true
    return item.label.toLowerCase().includes(token.toLowerCase())
  }

  const roLabel = items.find(item => item.value === value)?.label
  const zIndex = computeZIndex(ref.current)

  useOneUnderAnother(refInput, refList, {deps: [toggled], align: 'right'})

  return (
    <div className="select" ref={ref}>
      <Input
        value={toggled ? token : roLabel}
        ref={refInput}
        onChange={setToken}
        placeholder={toggled ? roLabel : placeholder}
        { ...rest }
        onClick={(ev) => {
          ev.stopPropagation()
          setToggled(true)
        }}
        rightBlock={
          <div
            className="select__opener"
            onClick={(e) => {e.stopPropagation(); e.preventDefault(); setToggled(!toggled)}}
          >
            <SvgIcon code={toggled ? 'chevronUp': 'chevronDown'} />
          </div>
        }
        onKeyDown={onKeyDown}
      />
      <Popup
        ref={refList}
        zIndex={zIndex}
        shown={toggled}
        onOutsideClick={() => setToggled(false)}
      >
        <ul className="select__ul" onMouseDown={_onChange}>
          {
            items.filter(filterFunction).map(o =>
              <li title={o.label} className="select__li" key={o.value} data-value={o.value}><span>{ o.label }</span></li>
            )
          }
        </ul>
      </Popup>
    </div>
  )
}
