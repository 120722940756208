import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useCopies, useLocationLanguagePrefix } from '@utils/hooks'
import { getItemFeed, GetItemFeedOut } from '@services/item'
import { Link, useParams } from 'react-router-dom'
import { NavButton } from '@components/NavButton'
import { FeedItem } from '@components/FeedItem'
import { useTranslation } from 'react-i18next'
import { alert } from '@components/MessageBox'
import { Footer } from '@components/Footer'
import { NoResults } from './noResults'
import { Coords } from '@serverTypes'
import { is } from '@utils/platform'
import './index.css'

let inputTimer: ReturnType<typeof setTimeout>
const PAGE_SIZE = 50

export const HomePage = () => {
  const { t } = useTranslation()
  const [token, setToken] = useState('')
  const params = useParams()
  const currentPage = params.pageId ? Number(params.pageId) : 1
  const [feed, setFeed] = useState<GetItemFeedOut | undefined>(window.ssrData.itemFeed)
  const refToken = useRef<string>(params.searchToken ?? '')
  const languagePrefix = useLocationLanguagePrefix()
  const [infoShown, setInfoShown] = useState(false)
  const [coordinates, setCoordinates] = useState<Coords|null>(null)
  const [gpsInProgress, setGpsInProgress] = useState(false)
  const copies = useCopies<{
    plhSearch: string, header: string, info: string[], gpsDisabled: string
    btnShowDistance: string, btnGoToFirst: string, btnShowInfo: string
  }>('pages.home')

  document.title = t('html.title')

  const getStuff = useCallback((reset?: boolean) => {
    if (reset) {
      getItemFeed({filter: {token: ''}, pagination: {start: 0}}).then(setFeed)
    } else {
      getItemFeed({filter: {token: refToken.current}, pagination: {start: refToken.current ? 0 : currentPage - 1}}).then(setFeed)
    }
  }, [refToken, currentPage])

  const onTokenChange = useCallback((ev: React.ChangeEvent<HTMLInputElement>) => {
    const token = ev.target.value

    setToken(token)
    refToken.current = token

    if (token.trim().length === 1) {
      return
    }

    clearTimeout(inputTimer)
    inputTimer = setTimeout(() => getStuff(), 350)
  }, [getStuff])

  const onInputKeyDown = useCallback((ev: React.KeyboardEvent<HTMLInputElement>) => {
    if (ev.key === 'Escape') {
      clearTimeout(inputTimer)
      setToken('')
      refToken.current = ''
      getStuff(true)
    }
  }, [getStuff])

  const pageNumbers = useMemo(() => {
    const pn: Array<number> = []
    const total = feed?.stats.total ?? 0
    const humanPageCount = Math.ceil(total / PAGE_SIZE)

    if (humanPageCount < 2) {
      return pn
    }

    for (let i = 1; i <= humanPageCount; i++) {
      pn.push(i)
    }

    return pn
  }, [feed])

  const onInfoClick = useCallback((ev: React.MouseEvent) => {
    ev.preventDefault()
    setInfoShown(x => !x)
  }, [])

  const onNavigationOn = useCallback((ev: React.MouseEvent) => {
    ev.preventDefault()
    if (coordinates) {
      setCoordinates(null)
    } else {
      setGpsInProgress(true)
      navigator.geolocation.getCurrentPosition(pos => {
        setCoordinates({
          lat: pos.coords.latitude,
          lon: pos.coords.longitude,
        })
        setGpsInProgress(false)
      }, (err) => {
        setGpsInProgress(false)
        if (err.code === 1) {
          // blocked, now or then, need to inform the user
          void alert(copies.gpsDisabled)
        }
      })
    }
  }, [coordinates, copies.gpsDisabled])

  // initial page load => no deps on getStuff
  // eslint-disable-next-line
  useEffect(getStuff, [currentPage])

  return (
    <div className="page-home">
      <div className="page-home__search-bar">
        <div className="page-home__search">
          <input
            autoFocus
            type="text"
            value={token}
            onChange={onTokenChange}
            onKeyDown={onInputKeyDown}
            placeholder={ copies.plhSearch || '' }
          />
          <NavButton
            to="#"
            icon="earth"
            tinted={!coordinates}
            onClick={onNavigationOn}
            title={copies.btnShowDistance}
            className={gpsInProgress ? 'rotating-earth' : ''}
          />
          { currentPage > 1 || params.searchToken ? <NavButton tinted icon="home" to={languagePrefix} title={copies.btnGoToFirst} /> : null }
          { is.MOBILE ? null : <NavButton tinted icon="information" onClick={onInfoClick} to="#" title={copies.btnShowInfo} /> }
          {/*<NavButton tinted icon="filterVariant" to={languagePrefix} />*/}
        </div>
      </div>

      <div className={`page-home__info-section ${infoShown ? 'page-home__info-section--open' : ''}`}>
        <h1>{ copies.header }</h1>
        { (copies.info ?? []).map((p, i) => <p key={i}>{p}</p>) }
      </div>

      <div className="page-home__content">
        {
          feed && feed.items.length ?
            <>
              <div className={`page-home__feed ${feed.items.length <= 3 ? 'page-home__feed--micro' : ''}`}>
                {
                  feed.items.map((item) => <FeedItem key={item.id} myLocation={coordinates} {...item} />)
                }
              </div>

              <nav className="page-home__pagination">
                <ul>
                  {
                    pageNumbers.map(number =>
                      <li key={number}>
                        <Link
                          to={`${languagePrefix}/feed/${number}`}
                          onClick={() => window.scrollTo(0, 0)}
                          className={number === currentPage ? 'active' : undefined}
                        >
                          { number }
                        </Link>
                      </li>
                    )
                  }
                </ul>
              </nav>
            </>
            :
            feed ? <NoResults /> : null
        }
      </div>
      <Footer />
    </div>
  )
}
