import React from 'react'
import './index.css'

type RowProps = React.HTMLAttributes<HTMLDivElement> & {
  center?: boolean
  bottom?: boolean
}

export const Row = ({children, className, center, bottom}: RowProps) => {
  return (
    <div className={`row ${className || ''} ${center ? 'center' : ''} ${bottom ? 'bottom' : ''}`}>
      { children }
    </div>
  )
}
