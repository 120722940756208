import React, { useCallback, useEffect, useState } from 'react'
import { Select } from '@components/UI/Select'
import { Button } from '@components/UI/Button'
import { toast } from '@components/UI/Toaster'
import { Input } from '@components/UI/Input'
import { usersToList } from '@utils/library'
import { useRandom } from '@utils/hooks'
import { apiCall } from '@services/api'
import { User } from '@serverTypes'

export const UserEditorModule = () => {
  const R = useRandom()
  const [users, setUsers] = useState<Array<User>>([])
  const [badId, setBadId] = useState(0)
  const [goodId, setGoodId] = useState(0)
  const [selectedId, setSelectedId] = useState(0)

  const userIndex = users.map(v => v.id).indexOf(selectedId)

  const saveUser = useCallback(async () => {
    if (await apiCall<User>('admin', 'updateUser', {user: users[userIndex]})) {
      toast({content: 'User saved'})
    }
  }, [userIndex, users])

  const linkUsers = useCallback(async () => {
    if (window.confirm('Are you sure you want to link users?')) {
      if (await apiCall<User>('admin', 'linkUsers', {badId, goodId})) {
        toast({content: 'Users merged'})
      }
    }
  }, [badId, goodId])

  useEffect(() => {
    apiCall<Array<User>>('admin', 'getUsers').then(x => setUsers(x || []))
  }, [])

  return (
    <div>
      <fieldset className="row">
        <legend>Link</legend>
        <Select<number> label="Bad user" items={usersToList(users)} value={badId} onChange={setBadId} />
        <Select<number> label="Good user" items={usersToList(users)} value={goodId} onChange={setGoodId} />
        {
          badId && goodId ?
            <Button onClick={linkUsers}>Link</Button>
            : null
        }
      </fieldset>
      <br/>
      <fieldset className="column">
        <legend>Edit</legend>
        <Select<number> label="User" items={usersToList(users)} value={selectedId} onChange={setSelectedId} />
        {
          userIndex !== -1 ?
            <>
              <Input label="Name" value={users[userIndex].name} onChange={x => users[userIndex].name = R(x) } />
              <Input label="Email" type="email" value={users[userIndex].email} onChange={x => users[userIndex].email = R(x) } />
              <Input label="Phone" value={users[userIndex].phone} onChange={x => users[userIndex].phone = R(x) } />
              <Input label="VAT" value={users[userIndex].vat} onChange={x => users[userIndex].vat = R(x) } />
              <br/>
              <Button onClick={saveUser}>Save</Button>
            </>
            : null
        }
      </fieldset>
    </div>
  )
}
