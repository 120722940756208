import { RichAircraftAd } from '@serverTypes'
import { deepCopy } from '@utils/system'

function mergeObjects (_obj1: Record<string, any>, obj2: Record<string, any>) {
  const obj1 = deepCopy<Record<string, any>>(_obj1)

  for (const key in obj2) {
    if (obj2.hasOwnProperty(key)) {
      if (obj1[key] && typeof obj1[key] === 'object') {
        obj1[key] = mergeObjects(obj1[key], obj2[key])
      } else {
        obj1[key] = obj2[key] || obj1[key]
      }
    }
  }

  return obj1
}

export const mergeRichAdStructures = (ad1: RichAircraftAd, ad2: RichAircraftAd): RichAircraftAd => {
  const result = mergeObjects(ad1, ad2) as RichAircraftAd

  result.files = [
    ...ad1.files,
    ...ad2.files,
  ]

  return result
}
