import { VendorEditorModule } from './modules/VendorEditor'
import { ServerHealthModule } from './modules/ServerHealth'
import { TypeEditorModule } from './modules/TypeEditor'
import { UserEditorModule } from './modules/UserEditor'
import { MergeAdsModule } from './modules/MergeAds'
import { Link, useParams } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import { LastAdsModule } from './modules/LastAds'
import { SEOModule } from './modules/SEO'
import { apiCall } from '@services/api'
import './index.css'

const menu = [
  { key: 'last-ads', label: 'Last ads' },
  { key: 'user-editor', label: 'User editor' },
  { key: 'vendor-editor', label: 'Vendor editor' },
  { key: 'type-editor', label: 'Type editor' },
  { key: 'merge-ads', label: 'Merge ads' },
  { key: 'server-health', label: 'Server health' },
  { key: 'seo', label: 'SEO' },
]

export const AdminPage = () => {
  const params = useParams()
  const [pageReady, setPageReady] = useState(false)
  const [mode, setMode] = useState(params.moduleId || 'last-ads')

  useEffect(() => {
    apiCall<boolean>('auth', 'check').then(data => {
      if (!data) {
        document.location.href = '/'
      } else {
        setPageReady(true)
        // load page data
      }
    })
  }, [])

  if (!pageReady) {
    return null
  }

  return (
    <div className="admin-home">
      <div className="admin-home__left">
        <ul className="admin-home__menu">
          <li><Link to="/">Home page</Link></li>
          <br/>
          {
            menu.map((item, i) =>
              <li
                key={i}
                onClick={() => setMode(item.key)}
                className={mode === item.key ? 'selected' : ''}
              >
                <Link to={`/admin/${item.key}`}>{ item.label }</Link>
              </li>
            )
          }
        </ul>
      </div>
      <div className="admin-home__right">
        { mode === 'last-ads' && <LastAdsModule /> }
        { mode === 'type-editor' && <TypeEditorModule /> }
        { mode === 'vendor-editor' && <VendorEditorModule /> }
        { mode === 'user-editor' && <UserEditorModule /> }
        { mode === 'merge-ads' && <MergeAdsModule /> }
        { mode === 'server-health' && <ServerHealthModule /> }
        { mode === 'seo' && <SEOModule /> }
      </div>
    </div>
  )
}
