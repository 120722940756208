import React, { CSSProperties, forwardRef, MouseEventHandler, ReactNode, useCallback, useEffect } from 'react'
import ReactDOM from 'react-dom'
import './index.css'

/**
 * This is a generic portal-based popup with a click assistant
 */

type PopupProps = {
  shown: boolean
  onOutsideClick?: MouseEventHandler<HTMLDivElement>
  children: ReactNode
  containerClass?: string
  zIndex?: number
  offset?: number
}

export const Popup = forwardRef<HTMLDivElement, PopupProps>((props, ref) => {
  const { shown, onOutsideClick, children, containerClass, zIndex, offset } = props

  // @ts-ignore
  const assist = useCallback((event) => {
    if (shown) {
      // check if the clicked element is inside the dropdown menu
      if (!event.target || !event.target.closest('.popup__container')) {
        // if the clicked element is not inside the dropdown menu, close the dropdown
        onOutsideClick && onOutsideClick(event)
      }
    }
  }, [onOutsideClick, shown])

  useEffect(() => {
    document.addEventListener('click', assist)

    return () => {
      document.removeEventListener('click', assist)
    }
  }, [assist])

  const style = { zIndex: zIndex ?? 1 } as CSSProperties

  style.marginTop = `${offset || 4}px`

  return ReactDOM.createPortal(
    shown ?
      <div
        ref={ref}
        style={style}
        className={`popup__container ${containerClass}`}
      >
        { children }
      </div>
      : null,
    document.body
  )
})

Popup.defaultProps = {
  containerClass: '',
}
