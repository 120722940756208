import { APIIncoming } from '@types'
import { getConfig } from './config'

export type APICallOptions = {
  get?: boolean
}

export type APIPagination = {
  start?: number
  length?: number
}

export async function apiCall<T>(endpoint: string, method?: string, payload?: any, options?: APICallOptions): Promise<T | undefined> {
  let result
  const rootUrl = getConfig().apiUrl
  const token = localStorage.getItem('token')

  try {
    if (options?.get) {
      result = await fetch(`${rootUrl}/${endpoint}?method=${method}${payload ? `&data=${JSON.stringify(payload)}` : ''}`, {
        headers: token ? {
          Token: token,
        } : {},
      })
    } else {
      result = await fetch(`${rootUrl}/${endpoint}`, {
        method: 'POST',
        body: JSON.stringify({
          method,
          data: payload,
        }),
        headers: token ? {
          Token: token,
        } : {},
      })
    }
  } catch (e: any) {
    return undefined // e.message
  }

  try {
    const json: APIIncoming = await result.json()

    if (json.isError) {
      return undefined // json.errMsg
    }

    return json.data as T
  } catch (e: any) {
    return undefined // e.message
  }
}

export async function fetchFile(url: string): Promise<[boolean, string | null]> {
  const result = await fetch(url)

  if (!result.ok) {
    return [false, null]
  }

  return [true, await result.text()]
}
