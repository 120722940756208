import { richAircraftTypesToList, vendorsToList } from '@utils/library'
import React, { useCallback, useEffect, useState } from 'react'
import { RichAircraftType, Vendor } from '@serverTypes'
import { Checkbox } from '@components/UI/Checkbox'
import { Select } from '@components/UI/Select'
import { toast } from '@components/UI/Toaster'
import { Button } from '@components/UI/Button'
import { Input } from '@components/UI/Input'
import { Row } from '@components/UI/Row'
import { useRandom } from '@utils/hooks'
import { apiCall } from '@services/api'

export const TypeEditorModule = () => {
  const R = useRandom()
  const [aircraftTypes, setAircraftTypes] = useState<Array<RichAircraftType>>([])
  const [badId, setBadId] = useState(0)
  const [goodId, setGoodId] = useState(0)
  const [selectedId, setSelectedId] = useState(0)
  const [vendors, setVendors] = useState<Array<Vendor>>([])

  const aircraftTypeIndex = aircraftTypes.map(v => v.id).indexOf(selectedId)

  const saveAircraftType = useCallback(async () => {
    if (await apiCall<RichAircraftType>('admin', 'updateAircraftType', {aircraftType: aircraftTypes[aircraftTypeIndex]})) {
      toast({content: 'Aircraft type saved'})
    }
  }, [aircraftTypeIndex, aircraftTypes])

  const linkAircraftTypes = useCallback(async () => {
    if (window.confirm('Are you sure you want to link aircraft types?')) {
      if (await apiCall<RichAircraftType>('admin', 'linkAircraftTypes', {badId, goodId})) {
        toast({content: 'Aircraft types merged'})
      }
    }
  }, [badId, goodId])

  useEffect(() => {
    apiCall<Array<Vendor>>('library', 'getVendors').then(x => setVendors(x || []))
    apiCall<Array<RichAircraftType>>('library', 'getAircraftTypesRich').then(x => setAircraftTypes(x || []))
  }, [])

  return (
    <div>
      <fieldset className="row">
        <legend>Link</legend>
        <Row bottom>
          <Select<number> label="Bad aircraft type" items={richAircraftTypesToList(aircraftTypes, true)} value={badId} onChange={setBadId} />
          <Select<number> label="Good aircraft type" items={richAircraftTypesToList(aircraftTypes, true)} value={goodId} onChange={setGoodId} />
          {
            badId && goodId ?
              <Button onClick={linkAircraftTypes}>Link</Button>
              : null
          }
        </Row>
      </fieldset>
      <br/>
      <fieldset className="column">
        <legend>Edit</legend>
        <Select<number> label="Aircraft type" items={richAircraftTypesToList(aircraftTypes, true)} value={selectedId} onChange={setSelectedId} />
        {
          aircraftTypeIndex !== -1 ?
            <>
              <Input
                label="Name"
                value={aircraftTypes[aircraftTypeIndex].name}
                onChange={x => aircraftTypes[aircraftTypeIndex].name = R(x) }
              />
              <Checkbox
                label="Verified"
                value={Boolean(aircraftTypes[aircraftTypeIndex].verified)}
                onChange={x => aircraftTypes[aircraftTypeIndex].verified = R(x) }
              />
              <Select
                label="Vendor"
                items={vendorsToList(vendors, true)}
                value={aircraftTypes[aircraftTypeIndex].vendor.id}
                onChange={x => aircraftTypes[aircraftTypeIndex].vendor = R(vendors.find(v => v.id === x))}
              />
              <br/>
              <Button onClick={saveAircraftType}>Save</Button>
            </>
            : null
        }
      </fieldset>
    </div>
  )
}
