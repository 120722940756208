import React from 'react'
import './index.css'

export type CheckboxProps = {
  value: boolean
  label?: string
  onChange: (v: boolean) => void
}

export const Checkbox = (props: CheckboxProps): JSX.Element => {
  const { label, value, onChange } = props

  return (
    <label className="checkbox">
       <span className="checkbox__input">
          <input
            type="checkbox"
            checked={value}
            onChange={() => onChange(!value)}
          />
          <span className="checkbox__control">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              focusable="false"
            >
              <path
                fill="none"
                strokeWidth="3"
                d="M1.73 12.91l6.37 6.37L22.79 4.59"
              />
            </svg>
          </span>
        </span>
      { label }
    </label>
  )
}
