import { Color, getColorCode } from '@utils/color'
import { camelToDashCase } from '@utils/text'
import paths, { Icon } from './paths'
import * as React from 'react'
import './index.css'

export interface SvgIconProps extends React.SVGAttributes<SVGElement> {
  code: Icon
  size?: number
  color?: Color
}

export const SvgIcon = (props: SvgIconProps) => {
  const { code, size, className, color, ...rest } = props

  const params: React.SVGProps<SVGPathElement> = {
    strokeWidth: 1,
    strokeLinecap: 'round',
    strokeLinejoin: 'round'
  }

  if (color) {
    params.fill = getColorCode(color)
  }

  return (
    <svg
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      style={{width: `${size}rem`, height: `${size}rem`}}
      className={`svg-icon icon-${camelToDashCase(String(code))} ${className}`}
      { ...rest }
    >
      <path {...params} d={paths[code] || paths.unset} />
    </svg>
  )
}

SvgIcon.defaultProps = {
  code: 'unset',
  size: 1.5,
  className: '',
}
